@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.s-home-hero {
    overflow: hidden;
}
.s-home-hero__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    padding-top: 24px;
    padding-bottom: 40px;

    position: relative;

    @media (min-width: $small-size) {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    @media (min-width: $medium-size) {
        grid-template-columns: 1.1fr 1fr;
        padding-top: 75px;
    }
    @media (min-width: $large-size) {
        padding-top: 100px;
    }
}

.s-home-hero__col-text {
    display: flex;
    flex-direction: column;
    align-items: start;

    position: relative;
    
    @media (min-width: $medium-size) {
        display: flex;

        padding-right: 20px;
    }
    @media (min-width: $large-size) {
        padding-right: 40px;
    }
}

.s-home-hero__bg-ico {
    width: 367px;
    height:  287px;

    @include img-wrap;

    position: absolute;
    right: 0;
    bottom: 4px;
    z-index: -1;
    transform: scale(.5);
    transform-origin: 100% 100%;

    @media (min-width: $smallest-size) {
        bottom: 0;

        transform: scale(.7);
    }
    @media (min-width: $small-size) {
        transform: scale(1) translateY(25%);
    }
    @media (min-width: $medium-size) {
        transform: scale(.75) translateY(15%);
    }
    @media (min-width: $largest-size) {
        transform: scale(1) translateY(15%);
    }

    img,
    svg {
        object-fit: contain;
    }
}
.s-home-hero__bg-ico-img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    
    object-fit: cover;
    object-position: center;
}

.s-home-hero__heading {
    margin-bottom: 20px;

    .m-heading__col-laguna {
        color: $color-orange-dark;
    }

    @media (min-width: $small-size) {
        margin-bottom: 24px;
    }
    @media (min-width: $medium-size) {
        grid-row: 1;
        grid-column: 1 / 3 span;
    }
    @media (min-width: $largest-size) {
        grid-row: auto;
        grid-column: auto;
    }
}

.s-home-hero__text {
    @media (min-width: $smallest-size) {
        max-width: 450px;
    }
    @media (min-width: $small-size) {
        max-width: 550px;
    }
    @media (min-width: $medium-size) {
        grid-row: 2;
        grid-column: 1 / 2 span;

        max-width: none;
    }
    @media (min-width: $largest-size) {
        grid-row: auto;
        grid-column: auto;

        max-width: 470px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.s-home-hero__btn {
    width: 100%;
    margin-top: 30px;

    text-align: center;

    @include btn__color-orange-dark;

    @media (min-width: $smallest-size) {
        width: auto;
    }
    @media (min-width: $small-size) {
        margin-bottom: 40px;
    }
    @media (min-width: $medium-size) {
        grid-row: 3;
        grid-column: 1 / 2 span;
        justify-self: start;

        max-width: 470px;
    }
    @media (min-width: $largest-size) {
        grid-row: auto;
        grid-column: auto;
        justify-self: auto;
    }
}

.s-home-hero__wr-video {
    width: 100%;
    
    @include video-wrap;
    @include aspect-ratio-w-pseudo(17, 10);

    @media (min-width: $smallest-size) {
        margin-left: auto;
        margin-right: auto;
        max-width: 450px;
    }
    @media (min-width: $small-size) {
        max-width: 550px;
    }
    @media (min-width: $medium-size) {
        grid-row: 2;

        max-width: none;
        margin-right: 0;
    }
    @media (min-width: $largest-size) {
        grid-row: auto;
    }
}