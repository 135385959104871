@function rem($pixels, $context: $font-size-base) {
  @return #{$pixels/$context}rem;
}

@mixin max-screen($size) {
  @media (max-width: $size) {
    @content;
  }
}
@mixin min-screen($size) {
  @media (min-width: $size + 1px) {
    @content;
  }
}

@mixin large() {
  @include max-screen($large-size) {
    @content;
  }
}

@mixin medium() {
  @include max-screen($medium-size) {
    @content;
  }
}

@mixin tablet() {
  @include max-screen($small-size) {
    @content;
  }
}

@mixin phone() {
  @include max-screen($smallest-size) {
    @content;
  }
}

@mixin large-mf() {
  @include min-screen($large-size) {
    @content;
  }
}

@mixin medium-mf() {
  @include min-screen($medium-size) {
    @content;
  }
}

@mixin tablet-mf() {
  @include min-screen($small-size) {
    @content;
  }
}

@mixin phone-mf() {
  @include min-screen($smallest-size) {
    @content;
  }
}

@mixin mobile-min() {
  @media (min-width: $ultra-smallest-size) {
    @content;
  }
}
@mixin mobile-mid() {
  @media (min-width: $smallest-size) {
    @content;
  }
}
@mixin mobile-max() {
  @media (min-width: $small-mid-size) {
    @content;
  }
}
@mixin tablet-min() {
  @media (min-width: $small-size) {
    @content;
  }
}
@mixin tablet-max() {
  @media (min-width: $medium-size) {
    @content;
  }
}
@mixin desktop-min() {
  @media (min-width: $large-size) {
    @content;
  }
}
@mixin desktop-max() {
  @media (min-width: $largest-size) {
    @content;
  }
}

@mixin hovering() {
  @media (hover: hover) {
    @content;
  }
}

@mixin heading {
  font-weight: 600;
  font-family: $font-family-solid;
  color: #3e3e3e;
}
@mixin heading__first {
  font-size: 36px;
  line-height: 120%;

  @media (min-width: $small-size) {
    font-size: 52px;
  }
  @media (min-width: $largest-size) {
    font-size: 70px;
  }

  .m-heading__italic {
      letter-spacing: -0.02em;
  }
}
@mixin heading__second {
  font-size: 28px;
  line-height: 130%;

  @media (min-width: $small-size) {
    font-size: 36px;
  }
  @media (min-width: $largest-size) {
    font-size: 48px;
  }
}
@mixin heading__third {
  font-size: 22px;
  line-height: 130%;

  @media (min-width: $small-size) {
      font-size: 28px;
  }
  @media (min-width: $largest-size) {
    font-size: 32px;
  }
}
@mixin heading__fourth {
  font-size: 18px;
  line-height: 120%;
  
  @media (min-width: $small-size) {
      font-size:  21px;
      line-height: 140%;
  }
}
@mixin heading__fifth {
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #676767;
}

@mixin btn {
  display: inline-flex;
  align-items: center;
  padding: 15px 25px;
  border: 1px solid transparent;

  font-family: $font-family-solid;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  text-decoration: none;
  text-align: center;

  border-radius: 50px;
  transition: 300ms ease-in-out;
  cursor: pointer;
}
@mixin btn__color-orange-dark {
  border-color: $color-orange-dark;
  background-color: $color-orange-dark;
  
  color: #ffffff;

  &:hover {
      @media (hover: hover) {
          background-color: transparent;
          color: $color-orange-dark;
      }
  }
}
@mixin btn__style-hollow-orange {
  border-color: $color-orange-dark;
  background-color: transparent;
  color: $color-orange-dark;

  @media (hover: hover) {
      &:hover {
          background-color: $color-orange-dark;
          color: #ffffff;
      }
  }
}

@mixin aspect-ratio-w-pseudo ($x: auto, $y: none) {

  @if $x and $y != none {

    aspect-ratio: #{$x} / #{$y};

    @supports not (aspect-ratio: #{$x} / #{$y}) {
        &::before {
            content: '';
            display: block;
  
            width: 100%;
            padding-bottom: ($y / $x) * 100%;
  
            position: relative;
            z-index: -101;
        }
    }

  } @else {

    aspect-ratio: $x;

    @supports not (aspect-ratio: $x) {
      &::before {
          content: '';
          display: block;

          @if $x == auto {
            width: 0;
            padding-bottom: 0;
          } @else {
            width: 100%;
            padding-bottom: (1 / $x) * 100%;
          }

          position: relative;
          z-index: -101;
      }
    }
  }
}

@mixin video-wrap {

  position: relative;
  overflow: hidden;

  & > .m-video {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: center;
  }
}

@mixin img-wrap {

  position: relative;
  overflow: hidden;

  img,
  svg {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
  }

}

@mixin tag {
  margin: 0;
  padding: 6px 16px;

  font-size: 10px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  color: $color-laguna-dark;

  background-color: $color-laguna-light;
  border-radius: 8px;

  @media (min-width: $smallest-size) {
      font-size: 12px;
  }
}